html {
	scroll-behavior: smooth;
}

body {
	font-family: MacPaw Fixel;
	font-weight: 400;
	font-feature-settings: 'salt' on;
	color: $color_1;
	background-color: $white;
}

#primary {
	flex: 1 0 auto;
}
#main {
	overflow: hidden;
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
	padding: 0;
	position: relative;
}

.wrapper {
	max-width: 1366px;
	width: 100%;
	margin: 0 auto;
	padding: 0 80px;
	@include lg {
		padding: 0 50px;
	}
	@include md {
		padding: 0 36px;
	}
	@include sm {
		padding: 0 20px;
	}
	@include xs {
		padding: 0 12px;
	}
}

.section-title {
	font-size: 60px;
	line-height: 100%;
	font-weight: 600;
	@include lg {
		font-size: 44px;
	}
	@include md {
		font-size: 38px;
	}
	@include sm {
		font-size: 26px;
	}
}

.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	line-height: 130%;
	font-weight: 600;
	text-align: center;
	color: $color_1;
	padding: 25px 36px;
	border: 2px solid $color_1;
	border-radius: 30px;
	transition: opacity .3s ease-in-out;
	@include lg {
		font-size: 18px;
		padding: 23px 30px;
		border-radius: 24px;
	}
	@include sm {
		font-size: 16px;
		padding: 18px 20px;
	}
	@include xs {
		display: flex;
		width: 100%;
	}
	&:hover,
	&:active {
		opacity: .4;
	}
	&--white {
		color: $white;
		border: 2px solid $white;
	}
	&--blue {
		color: $color_2;
		border: 2px solid $color_2;
	}
}

@include lg {
	
}
@include md {
	
}
@include sm {
	
}
@include xs {
	
}