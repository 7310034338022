.first-screen {
	background-color: $color_2;
	border-radius: 10px;
	&__wrap {
		min-height: 610px;
		display: flex;
		align-items: center;
		padding: 140px 0;
		position: relative;
		@include lg {
			min-height: 510px;
			padding: 120px 0;
		}
		@include md {
			min-height: unset;
			padding: 100px 0;
		}
		@include sm {
			padding: 80px 0;
		}
	}
	&__content {
		width: calc(100% - 500px);
		position: relative;
		z-index: 1;
		@include lg {
			width: calc(100% - 400px);
		}
		@include md {
			width: calc(100% - 240px);
		}
		@include sm {
			width: calc(100% - 184px);
		}
		@include xs {
			width: 100%;
		}
	}
	&__title {
		color: $white;
	}
	&__btn-wrap {
		display: inline-flex;
		margin-top: 40px;
		padding-bottom: 30px;
		position: relative;
		@include lg {
			padding-bottom: 27px;
		}
		@include md {
			padding-bottom: 20px;
		}
		@include sm {
			margin-top: 30px;
			padding-bottom: 24px;
		}
		@include xs {
			display: block;
			padding-bottom: 0;
		}
	}
	&__btn-label {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		font-size: 24px;
		line-height: 100%;
		font-weight: 600;
		text-align: center;
		letter-spacing: 0.01em;
		color: $color_1;
		white-space: nowrap;
		padding: 0 20px;
		background-color: $color_3;
		border-radius: 100px;
		position: absolute;
		right: 0;
		bottom: 15px;
		transform: translateX(50%) rotate(-4.5deg);
		@include lg {
			height: 36px;
			font-size: 20px;
			bottom: 12px;
		}
		@include md {
			height: 30px;
			font-size: 18px;
			padding: 0 15px;
			bottom: 11px;
			transform: translateX(33%) rotate(-4.5deg);
		}
		@include sm {
			height: 22px;
			font-size: 14px;
			padding: 0 10px;
			bottom: 8px;
			transform: translateX(20%) rotate(-4.5deg);
		}
		@include xs {
			bottom: -8px;
			transform: translateX(0) rotate(-4.5deg);
		}
	}
	&__img {
		display: block;
		width: 464px;
		position: absolute;
		right: 0;
		@include lg {
			width: 364px;
		}
		@include md {
			width: 216px;
			top: 100px;
		}
		@include sm {
			width: 168px;
			top: 80px;
		}
		@include xs {
			top: 12px;
		}
	}
}

.about-us {
	padding-top: 120px;
	padding-bottom: 160px;
	background: linear-gradient(0deg, #C7C4E3 0%, #FFFFFF 99.4%, #FFFFFF 100%);
	border-radius: 10px;
	@include lg {
		padding-top: 100px;
		padding-bottom: 140px;
	}
	@include md {
		padding-top: 80px;
		padding-bottom: 120px;
	}
	@include sm {
		padding-top: 60px;
		padding-bottom: 100px;
	}
	&__wrap {
		padding: 0 30px;
		@include lg {
			padding: 0;
		}
	}
	&__title {
		color: $color_1;
		margin-bottom: 80px;
		@include lg {
			margin-bottom: 60px;
		}
		@include sm {
			margin-bottom: 40px;
		}
	}
	&__list {
		display: flex;
		margin: 0 -20px;
		@include lg {
			margin: 0 -18px;
		}
		@include md {
			display: block;
			margin: 0;
			padding-right: 60px;
		}
		@include sm {
			padding-right: 0;
		}
	}
}
.about-us-item {
	width: 530px;
	margin: 0 20px;
	padding-left: 50px;
	position: relative;
	@include lg {
		width: calc(50% - 36px);
		margin: 0 18px;
	}
	@include md {
		width: 100%;
		margin: 0 0 63px;
	}
	@include sm {
		margin-bottom: 30px;
		padding-left: 46px;
	}
	@include xs {
		padding-left: 30px;
	}
	&:last-child {
		margin-bottom: 0;
	}
	svg {
		width: 30px;
		height: 30px;
		fill: $color_3;
		position: absolute;
		top: 0;
		left: 0;
		@include sm {
			top: -7px;
		}
		@include xs {
			width: 20px;
			height: 20px;
			top: -2px;
		}
	}
	&__title {
		font-size: 36px;
		line-height: 100%;
		font-weight: 600;
		margin-bottom: 30px;
		@include lg {
			font-size: 30px;
			margin-bottom: 20px;
		}
		@include sm {
			font-size: 20px;
		}
	}
	&__description {
		font-size: 20px;
		line-height: 130%;
		font-weight: 600;
		@include lg {
			font-size: 18px;
		}
		@include sm {
			font-size: 16px;
		}
	}
}

.audience {
	padding-top: 120px;
	padding-bottom: 140px;
	@include lg {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	@include sm {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	&__wrap {
		padding: 0 30px;
		@include lg {
			padding: 0;
		}
	}
	&__title {
		color: $color_1;
		margin-bottom: 50px;
		@include lg {
			margin-bottom: 30px;
		}
	}
	&__subtitle {
		font-size: 30px;
		line-height: 100%;
		margin-bottom: 80px;
		@include lg {
			font-size: 24px;
			margin-bottom: 60px;
		}
		@include sm {
			font-size: 18px;
			margin-bottom: 40px;
		}
	}
	&__list {
		display: flex;
		justify-content: space-between;
		@include md {
			flex-wrap: wrap;
			width: 530px;
			margin: 0 auto -60px;
		}
		@include sm {
			width: 500px;
			margin: 0 auto -40px;
		}
		@include xs {
			display: block;
			width: 100%;
			margin: 0 0 -40px;
		}
	}
}
.audience-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	@include md {
		width: 250px;
		margin: 0 0 60px;
	}
	@include sm {
		width: 220px;
		margin: 0 0 40px;
	}
	@include xs {
		width: 100%;
	}
	&__icons {
		width: 136px;
		height: 120px;
		margin-bottom: 50px;
		position: relative;
		@include lg {
			margin-bottom: 30px;
		}
		@include sm {
			margin-bottom: 20px;
		}
	}
	&__top-icon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		img {
			display: block;
			width: 110px;
			height: 92px;
		}
	}
	&__bottom-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 78px;
		height: 68px;
		background-color: $color_2;
		border-radius: 12px;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 4;
		img {
			display: block;
			width: 44px;
			height: 44px;
		}
	}
	&__title {
		font-size: 30px;
		line-height: 100%;
		font-weight: 600;
		margin-bottom: 20px;
		@include lg {
			font-size: 24px;
			margin-bottom: 15px;
		}
		@include sm {
			font-size: 20px;
			margin-bottom: 10px;
		}
	}
	&__subtitle {
		font-size: 20px;
		line-height: 130%;
		@include lg {
			font-size: 18px;
		}
		@include sm {
			font-size: 16px;
		}
	}
}

.slogan-section {
	padding-top: 160px;
	padding-bottom: 140px;
	background-color: $color_3;
	border-radius: 10px;
	background-image: url('./img/slogan-bg-pattern.png');
	background-repeat: repeat;
	background-size: 268px;
	background-position: top -30px left -30px;
	@include lg {
		padding-top: 130px;
		padding-bottom: 130px;
		background-size: 230px;
	}
	@include md {
		padding-top: 110px;
		padding-bottom: 110px;
		background-size: 184px;
	}
	@include sm {
		padding-top: 100px;
		padding-bottom: 100px;
		background-size: 160px;
	}
	@include xs {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 30px;
		@include lg {
			padding: 0;
		}
	}
	span {
		display: block;
		font-size: 60px;
		line-height: 100%;
		font-weight: 600;
		color: $white;
		padding: 15px 30px;
		margin-bottom: 15px;
		background-color: $color_2;
		@include lg {
			font-size: 44px;
		}
		@include md {
			font-size: 38px;
		}
		@include sm {
			font-size: 26px;
		}
		@include xs {
			width: 100%;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.manifesto {
	padding-top: 120px;
	padding-bottom: 140px;
	@include lg {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	@include sm {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	&__wrap {
		display: flex;
		align-items: flex-start;
		padding-left: 30px;
		@include lg {
			padding-left: 0;
		}
		@include xs {
			display: block;
		}
	}
	&__img {
		flex: 1;
		display: block;
		width: 470px;
		margin-right: 100px;
		@include lg {
			width: 400px;
			margin-right: 80px;
		}
		@include md {
			width: 336px;
			margin-right: 24px;
		}
		@include sm {
			width: 214px;
			margin-right: 14px;
		}
		@include xs {
			margin: 0 auto	40px;
		}
	}
	&__content {
		@include xs {
			text-align: center;
		}
	}
	&__title {
		color: $color_1;
	}
	&__description {
		font-size: 20px;
		line-height: 130%;
		margin-top: 40px;
		@include lg {
			font-size: 18px;
			margin-top: 25px;
		}
		@include sm {
			font-size: 16px;
			margin-top: 20px;
		}
	}
	&__btn-wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 44px;
		@include lg {
			margin-top: 35px;
		}
		@include sm {
			margin-top: 30px;
		}
	}
	&__btn {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
		&--custom {
			text-align: left;
			background-color: $color_3;
			border: 2px solid $color_3;
			transition: all .3s ease-in-out;
			@include xs {
				text-align: center;
			}
			&:hover,
			&:active {
				opacity: 1;
				background-color: rgba(244, 159, 15, .6);
				border: 2px solid rgba(244, 159, 15, 0);
			}
		}
	}
}

.contacts {
	padding-top: 120px;
	padding-bottom: 140px;
	background-color: $color_2;
	@include lg {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	@include sm {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	&__wrap {
		padding: 0 30px;
		@include lg {
			padding: 0;
		}
	}
	&__title {
		color: $white;
		margin-bottom: 40px;
		@include lg {
			margin-bottom: 30px;
		}
	}
	&__description {
		font-size: 20px;
		line-height: 130%;
		color: $white;
		@include lg {
			font-size: 18px;
		}
		@include sm {
			font-size: 16px;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 60px;
		font-size: 30px;
		line-height: 100%;
		color: $white;
		@include lg {
			font-size: 24px;
		}
		@include sm {
			font-size: 18px;
		}
	}
}

.subscription {
	width: 584px;
	@include lg {
		width: 524px;
	}
	@include md {
		order: 1;
		width: 100%;
	}
	&__title {
		margin-bottom: 40px;
		@include lg {
			margin-bottom: 30px;
		}
		@include md {
			br {
				display: none;
			}
		}
	}
	&__form {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 80px;
		@include lg {
			height: 74px;	
		}
		@include sm {
			height: 60px;
		}
	}
	&__input {
		flex: 1;
		height: 100%;
		font-family: MacPaw Fixel;
		font-size: 20px;
		line-height: 130%;
		font-weight: 600;
		color: $white;
		font-feature-settings: 'salt' on;
		padding: 0 66px 0 36px;
		margin-right: -30px;
		background-color: transparent;
		border: 2px solid $white;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		@include lg {
			font-size: 18px;
			padding: 0 54px 0 30px;
			margin-right: -24px;
			border-top-left-radius: 24px;
			border-bottom-left-radius: 24px;
		}
		@include sm {
			font-size: 16px;
			padding: 0 44px 0 20px;
		}
		@include xs {
			width: 200px;
		}
		&::-webkit-input-placeholder {
			color: rgba(255, 255, 255, .4);
		}
		&:-moz-placeholder {
			color: rgba(255, 255, 255, .4);
		}
		&::-moz-placeholder {
			color: rgba(255, 255, 255, .4);
		}
		&:-ms-input-placeholder {
			color: rgba(255, 255, 255, .4);
		}
		&::-ms-input-placeholder {
			color: rgba(255, 255, 255, .4);
		}
		&:placeholder {
			color: rgba(255, 255, 255, .4);
		}
		&.invalid {
			border: 2px solid $color_4;
			color: $color_4;
		}
	}
	&__btn {
		flex: none;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		font-size: 20px;
		line-height: 130%;
		font-weight: 600;
		text-align: center;
		color: $color_2;
		padding: 0 36px;
		background-color: $white;
		border: none;
		border-radius: 30px;
		transition: background-color .3s ease-in-out;
		@include lg {
			font-size: 18px;
			padding: 0 30px;
			border-radius: 24px;
		}
		@include sm {
			font-size: 16px;
			padding: 0 20px;
		}
		&:hover,
		&:active {
			background-color: $color_3;
		}
	}
}
.contacts-email {
	width: calc(100% - 584px);
	padding-left: 114px;
	@include lg {
		width: calc(100% - 524px);
		padding-left: 36px;
	}
	@include md {
		order: 3;
		width: auto;
		padding-left: 0;
		margin-top: 60px;
	}
	@include xs {
		width: 100%;
	}
	a {
		font-weight: 600;
		color: $white;
		text-decoration: underline;
		text-underline-position: under;
		transition: opacity .3s ease-in-out;
		&:hover,
		&:active {
			opacity: .4;
		}
	}
}
.contacts-facebook {
	width: 100%;
	margin-top: 80px;
	@include lg {
		margin-top: 60px;
	}
	@include md {
		order: 2;
		width: auto;
		margin-right: 70px;
	}
	@include sm {
		margin-right: auto;
		padding-right: 20px;
	}
	@include xs {
		width: 100%;
		margin-right: 0;
		padding-right: 0;
	}
	a {
		font-weight: 600;
		color: $white;
		text-decoration: underline;
		text-underline-position: under;
		transition: opacity .3s ease-in-out;
		@include md {
			display: block;
		}
		@include xs {
			display: inline-block;
		}
		&:hover,
		&:active {
			opacity: .4;
		}
	}
}