.header {
	width: 100%;
	background-color: $white;
	&__wrap {
		display: flex;
		align-items: center;
		width: 100%;
		height: 142px;
		padding: 40px 0;
		@include lg {
			height: 122px;
			padding: 30px 0;
		}
		@include md {
			flex-wrap: wrap;
			height: auto;
			padding: 0;
		}
	}
	&__logo {
		flex: none;
		display: flex;
		align-items: center;
		width: auto;
		height: 56px;
		margin-right: 20px;
		@include md {
			order: 1;
			margin: 20px 0;
		}
		@include sm {
			height: 45px;
		}
		img {
			display: block;
			width: auto;
			height: 100%;
		}
	}
	&__nav {
		height: 100%;
		margin-left: auto;
		@include md {
			order: 3;
			width: 100%;
			border-top: 1px solid $color_1;
		}
	}
	&__menu {
		list-style: none;
		display: flex;
		height: 100%;
		@include md {
			justify-content: space-between;
		}
		.menu-item {
			height: 100%;
			margin-right: 45px;
			@include lg {
				margin-right: 30px;
			}
			@include md {
				margin-right: 15px;
			}
			@include xs {
				margin-right: 10px;
			}
			&:last-child {
				margin-right: 0;
			}
			a {
				display: flex;
				align-items: center;
				height: 100%;
				font-size: 20px;
				line-height: 130%;
				color: $color_1;
				padding: 5px 0;
				transition: opacity .3s ease-in-out;
				@include lg {
					font-size: 18px;
				}
				@include md {
					padding: 30px 0;
				}
				@include sm {
					font-size: 16px;
					padding: 30px 0 27px;
				}
				@include xs {
					font-size: 14px;
					padding: 30px 0;
				}
				&:hover,
				&:active {
					opacity: .4;
				}
			}
		}
	}
	&__lang {
		display: flex;
		align-items: center;
		height: 100%;
		padding-left: 55px;
		margin-left: 55px;
		border-left: 1px solid $color_1;
		@include lg {
			padding-left: 30px;
			margin-left: 30px;
		}
		@include md {
			order: 2;
			padding-left: 0;
			margin-left: auto;
			border-left: none;
		}
		@include xs {
			
		}
		.lang-item {
			display: block;
			font-size: 20px;
			line-height: 130%;
			font-weight: 600;
			color: $color_1;
			padding: 5px 0;
			margin-right: 30px;
			transition: opacity .3s ease-in-out;
			@include lg {
				font-size: 18px;
				margin-right: 15px;
			}
			@include md {
				margin-right: 20px;
			}
			@include sm {
				font-size: 16px;
			}
			@include xs {
				font-size: 14px;
			}
			&:last-child {
				margin-right: 0;
			}
			&.active {
				font-weight: 400;
				pointer-events: none;
			}
			&:hover,
			&:active {
				opacity: .4;
			}
		}
	}
}