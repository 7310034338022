@font-face {
	font-family: 'MacPaw Fixel';
	src: url('fonts/MacPawFixelText-Regular.woff2') format('woff2'),
		 url('fonts/MacPawFixelText-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'MacPaw Fixel';
	src: url('fonts/MacPawFixelText-SemiBold.woff2') format('woff2'),
		 url('fonts/MacPawFixelText-SemiBold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}