.footer {
	padding-top: 50px;	
	padding-bottom: 50px;
	background-color: $color_1;
	@include lg {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	@include md {
		padding-top: 37px;
		padding-bottom: 37px;
	}
	@include sm {
		padding-top: 30px;
		padding-bottom: 40px;
	}
	&__wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include sm {
			display: block;
		}
	}
	&__logo {
		flex: none;
		display: flex;
		align-items: center;
		width: auto;
		height: 62px;
		margin-right: 20px;
		@include lg {
			height: 52px;
		}
		@include md {
			height: 46px;
		}
		img {
			display: block;
			width: auto;
			height: 100%;
		}
	}
	&__copyright {
		width: 53%;
		font-size: 20px;
		line-height: 130%;
		color: $white;
		@include lg {
			width: 57%;
			font-size: 18px;
		}
		@include sm {
			width: 100%;
			font-size: 16px;
			margin-top: 20px;
		}
	}
}