/* NORMALIZE */
/* ================================================ */
html, body, div, span, applet, main, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, main, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: Arial, Tahoma, sans-serif;
  line-height: 1;
  background-color: #ffffff;
  height: 100%;
  position: relative;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
}

html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

input, textarea {
  color: #333;
  outline: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}

input[type=button], input[type=submit] {
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;
}

button {
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;
}

td, form {
  margin: 0px;
  padding: 0px;
}

a {
  color: #000100;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/*input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}*/
input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

@font-face {
  font-family: "MacPaw Fixel";
  src: url("fonts/MacPawFixelText-Regular.woff2") format("woff2"), url("fonts/MacPawFixelText-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "MacPaw Fixel";
  src: url("fonts/MacPawFixelText-SemiBold.woff2") format("woff2"), url("fonts/MacPawFixelText-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: MacPaw Fixel;
  font-weight: 400;
  font-feature-settings: "salt" on;
  color: #2C255B;
  background-color: #FFFFFF;
}

#primary {
  flex: 1 0 auto;
}

#main {
  overflow: hidden;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.wrapper {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  padding: 0 80px;
}
@media (max-width: 1365px) {
  .wrapper {
    padding: 0 50px;
  }
}
@media (max-width: 1023px) {
  .wrapper {
    padding: 0 36px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding: 0 20px;
  }
}
@media (max-width: 575px) {
  .wrapper {
    padding: 0 12px;
  }
}

.section-title {
  font-size: 60px;
  line-height: 100%;
  font-weight: 600;
}
@media (max-width: 1365px) {
  .section-title {
    font-size: 44px;
  }
}
@media (max-width: 1023px) {
  .section-title {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .section-title {
    font-size: 26px;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  color: #2C255B;
  padding: 25px 36px;
  border: 2px solid #2C255B;
  border-radius: 30px;
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 1365px) {
  .btn {
    font-size: 18px;
    padding: 23px 30px;
    border-radius: 24px;
  }
}
@media (max-width: 767px) {
  .btn {
    font-size: 16px;
    padding: 18px 20px;
  }
}
@media (max-width: 575px) {
  .btn {
    display: flex;
    width: 100%;
  }
}
.btn:hover, .btn:active {
  opacity: 0.4;
}
.btn--white {
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
}
.btn--blue {
  color: #544AF1;
  border: 2px solid #544AF1;
}

.header {
  width: 100%;
  background-color: #FFFFFF;
}
.header__wrap {
  display: flex;
  align-items: center;
  width: 100%;
  height: 142px;
  padding: 40px 0;
}
@media (max-width: 1365px) {
  .header__wrap {
    height: 122px;
    padding: 30px 0;
  }
}
@media (max-width: 1023px) {
  .header__wrap {
    flex-wrap: wrap;
    height: auto;
    padding: 0;
  }
}
.header__logo {
  flex: none;
  display: flex;
  align-items: center;
  width: auto;
  height: 56px;
  margin-right: 20px;
}
@media (max-width: 1023px) {
  .header__logo {
    order: 1;
    margin: 20px 0;
  }
}
@media (max-width: 767px) {
  .header__logo {
    height: 45px;
  }
}
.header__logo img {
  display: block;
  width: auto;
  height: 100%;
}
.header__nav {
  height: 100%;
  margin-left: auto;
}
@media (max-width: 1023px) {
  .header__nav {
    order: 3;
    width: 100%;
    border-top: 1px solid #2C255B;
  }
}
.header__menu {
  list-style: none;
  display: flex;
  height: 100%;
}
@media (max-width: 1023px) {
  .header__menu {
    justify-content: space-between;
  }
}
.header__menu .menu-item {
  height: 100%;
  margin-right: 45px;
}
@media (max-width: 1365px) {
  .header__menu .menu-item {
    margin-right: 30px;
  }
}
@media (max-width: 1023px) {
  .header__menu .menu-item {
    margin-right: 15px;
  }
}
@media (max-width: 575px) {
  .header__menu .menu-item {
    margin-right: 10px;
  }
}
.header__menu .menu-item:last-child {
  margin-right: 0;
}
.header__menu .menu-item a {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 20px;
  line-height: 130%;
  color: #2C255B;
  padding: 5px 0;
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 1365px) {
  .header__menu .menu-item a {
    font-size: 18px;
  }
}
@media (max-width: 1023px) {
  .header__menu .menu-item a {
    padding: 30px 0;
  }
}
@media (max-width: 767px) {
  .header__menu .menu-item a {
    font-size: 16px;
    padding: 30px 0 27px;
  }
}
@media (max-width: 575px) {
  .header__menu .menu-item a {
    font-size: 14px;
    padding: 30px 0;
  }
}
.header__menu .menu-item a:hover, .header__menu .menu-item a:active {
  opacity: 0.4;
}
.header__lang {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 55px;
  margin-left: 55px;
  border-left: 1px solid #2C255B;
}
@media (max-width: 1365px) {
  .header__lang {
    padding-left: 30px;
    margin-left: 30px;
  }
}
@media (max-width: 1023px) {
  .header__lang {
    order: 2;
    padding-left: 0;
    margin-left: auto;
    border-left: none;
  }
}
.header__lang .lang-item {
  display: block;
  font-size: 20px;
  line-height: 130%;
  font-weight: 600;
  color: #2C255B;
  padding: 5px 0;
  margin-right: 30px;
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 1365px) {
  .header__lang .lang-item {
    font-size: 18px;
    margin-right: 15px;
  }
}
@media (max-width: 1023px) {
  .header__lang .lang-item {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .header__lang .lang-item {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .header__lang .lang-item {
    font-size: 14px;
  }
}
.header__lang .lang-item:last-child {
  margin-right: 0;
}
.header__lang .lang-item.active {
  font-weight: 400;
  pointer-events: none;
}
.header__lang .lang-item:hover, .header__lang .lang-item:active {
  opacity: 0.4;
}

.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #2C255B;
}
@media (max-width: 1365px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (max-width: 1023px) {
  .footer {
    padding-top: 37px;
    padding-bottom: 37px;
  }
}
@media (max-width: 767px) {
  .footer {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}
.footer__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .footer__wrap {
    display: block;
  }
}
.footer__logo {
  flex: none;
  display: flex;
  align-items: center;
  width: auto;
  height: 62px;
  margin-right: 20px;
}
@media (max-width: 1365px) {
  .footer__logo {
    height: 52px;
  }
}
@media (max-width: 1023px) {
  .footer__logo {
    height: 46px;
  }
}
.footer__logo img {
  display: block;
  width: auto;
  height: 100%;
}
.footer__copyright {
  width: 53%;
  font-size: 20px;
  line-height: 130%;
  color: #FFFFFF;
}
@media (max-width: 1365px) {
  .footer__copyright {
    width: 57%;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .footer__copyright {
    width: 100%;
    font-size: 16px;
    margin-top: 20px;
  }
}

.first-screen {
  background-color: #544AF1;
  border-radius: 10px;
}
.first-screen__wrap {
  min-height: 610px;
  display: flex;
  align-items: center;
  padding: 140px 0;
  position: relative;
}
@media (max-width: 1365px) {
  .first-screen__wrap {
    min-height: 510px;
    padding: 120px 0;
  }
}
@media (max-width: 1023px) {
  .first-screen__wrap {
    min-height: unset;
    padding: 100px 0;
  }
}
@media (max-width: 767px) {
  .first-screen__wrap {
    padding: 80px 0;
  }
}
.first-screen__content {
  width: calc(100% - 500px);
  position: relative;
  z-index: 1;
}
@media (max-width: 1365px) {
  .first-screen__content {
    width: calc(100% - 400px);
  }
}
@media (max-width: 1023px) {
  .first-screen__content {
    width: calc(100% - 240px);
  }
}
@media (max-width: 767px) {
  .first-screen__content {
    width: calc(100% - 184px);
  }
}
@media (max-width: 575px) {
  .first-screen__content {
    width: 100%;
  }
}
.first-screen__title {
  color: #FFFFFF;
}
.first-screen__btn-wrap {
  display: inline-flex;
  margin-top: 40px;
  padding-bottom: 30px;
  position: relative;
}
@media (max-width: 1365px) {
  .first-screen__btn-wrap {
    padding-bottom: 27px;
  }
}
@media (max-width: 1023px) {
  .first-screen__btn-wrap {
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .first-screen__btn-wrap {
    margin-top: 30px;
    padding-bottom: 24px;
  }
}
@media (max-width: 575px) {
  .first-screen__btn-wrap {
    display: block;
    padding-bottom: 0;
  }
}
.first-screen__btn-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 24px;
  line-height: 100%;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.01em;
  color: #2C255B;
  white-space: nowrap;
  padding: 0 20px;
  background-color: #F49F0F;
  border-radius: 100px;
  position: absolute;
  right: 0;
  bottom: 15px;
  transform: translateX(50%) rotate(-4.5deg);
}
@media (max-width: 1365px) {
  .first-screen__btn-label {
    height: 36px;
    font-size: 20px;
    bottom: 12px;
  }
}
@media (max-width: 1023px) {
  .first-screen__btn-label {
    height: 30px;
    font-size: 18px;
    padding: 0 15px;
    bottom: 11px;
    transform: translateX(33%) rotate(-4.5deg);
  }
}
@media (max-width: 767px) {
  .first-screen__btn-label {
    height: 22px;
    font-size: 14px;
    padding: 0 10px;
    bottom: 8px;
    transform: translateX(20%) rotate(-4.5deg);
  }
}
@media (max-width: 575px) {
  .first-screen__btn-label {
    bottom: -8px;
    transform: translateX(0) rotate(-4.5deg);
  }
}
.first-screen__img {
  display: block;
  width: 464px;
  position: absolute;
  right: 0;
}
@media (max-width: 1365px) {
  .first-screen__img {
    width: 364px;
  }
}
@media (max-width: 1023px) {
  .first-screen__img {
    width: 216px;
    top: 100px;
  }
}
@media (max-width: 767px) {
  .first-screen__img {
    width: 168px;
    top: 80px;
  }
}
@media (max-width: 575px) {
  .first-screen__img {
    top: 12px;
  }
}

.about-us {
  padding-top: 120px;
  padding-bottom: 160px;
  background: linear-gradient(0deg, #C7C4E3 0%, #FFFFFF 99.4%, #FFFFFF 100%);
  border-radius: 10px;
}
@media (max-width: 1365px) {
  .about-us {
    padding-top: 100px;
    padding-bottom: 140px;
  }
}
@media (max-width: 1023px) {
  .about-us {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .about-us {
    padding-top: 60px;
    padding-bottom: 100px;
  }
}
.about-us__wrap {
  padding: 0 30px;
}
@media (max-width: 1365px) {
  .about-us__wrap {
    padding: 0;
  }
}
.about-us__title {
  color: #2C255B;
  margin-bottom: 80px;
}
@media (max-width: 1365px) {
  .about-us__title {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .about-us__title {
    margin-bottom: 40px;
  }
}
.about-us__list {
  display: flex;
  margin: 0 -20px;
}
@media (max-width: 1365px) {
  .about-us__list {
    margin: 0 -18px;
  }
}
@media (max-width: 1023px) {
  .about-us__list {
    display: block;
    margin: 0;
    padding-right: 60px;
  }
}
@media (max-width: 767px) {
  .about-us__list {
    padding-right: 0;
  }
}

.about-us-item {
  width: 530px;
  margin: 0 20px;
  padding-left: 50px;
  position: relative;
}
@media (max-width: 1365px) {
  .about-us-item {
    width: calc(50% - 36px);
    margin: 0 18px;
  }
}
@media (max-width: 1023px) {
  .about-us-item {
    width: 100%;
    margin: 0 0 63px;
  }
}
@media (max-width: 767px) {
  .about-us-item {
    margin-bottom: 30px;
    padding-left: 46px;
  }
}
@media (max-width: 575px) {
  .about-us-item {
    padding-left: 30px;
  }
}
.about-us-item:last-child {
  margin-bottom: 0;
}
.about-us-item svg {
  width: 30px;
  height: 30px;
  fill: #F49F0F;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 767px) {
  .about-us-item svg {
    top: -7px;
  }
}
@media (max-width: 575px) {
  .about-us-item svg {
    width: 20px;
    height: 20px;
    top: -2px;
  }
}
.about-us-item__title {
  font-size: 36px;
  line-height: 100%;
  font-weight: 600;
  margin-bottom: 30px;
}
@media (max-width: 1365px) {
  .about-us-item__title {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .about-us-item__title {
    font-size: 20px;
  }
}
.about-us-item__description {
  font-size: 20px;
  line-height: 130%;
  font-weight: 600;
}
@media (max-width: 1365px) {
  .about-us-item__description {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .about-us-item__description {
    font-size: 16px;
  }
}

.audience {
  padding-top: 120px;
  padding-bottom: 140px;
}
@media (max-width: 1365px) {
  .audience {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .audience {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.audience__wrap {
  padding: 0 30px;
}
@media (max-width: 1365px) {
  .audience__wrap {
    padding: 0;
  }
}
.audience__title {
  color: #2C255B;
  margin-bottom: 50px;
}
@media (max-width: 1365px) {
  .audience__title {
    margin-bottom: 30px;
  }
}
.audience__subtitle {
  font-size: 30px;
  line-height: 100%;
  margin-bottom: 80px;
}
@media (max-width: 1365px) {
  .audience__subtitle {
    font-size: 24px;
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .audience__subtitle {
    font-size: 18px;
    margin-bottom: 40px;
  }
}
.audience__list {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .audience__list {
    flex-wrap: wrap;
    width: 530px;
    margin: 0 auto -60px;
  }
}
@media (max-width: 767px) {
  .audience__list {
    width: 500px;
    margin: 0 auto -40px;
  }
}
@media (max-width: 575px) {
  .audience__list {
    display: block;
    width: 100%;
    margin: 0 0 -40px;
  }
}

.audience-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media (max-width: 1023px) {
  .audience-item {
    width: 250px;
    margin: 0 0 60px;
  }
}
@media (max-width: 767px) {
  .audience-item {
    width: 220px;
    margin: 0 0 40px;
  }
}
@media (max-width: 575px) {
  .audience-item {
    width: 100%;
  }
}
.audience-item__icons {
  width: 136px;
  height: 120px;
  margin-bottom: 50px;
  position: relative;
}
@media (max-width: 1365px) {
  .audience-item__icons {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .audience-item__icons {
    margin-bottom: 20px;
  }
}
.audience-item__top-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.audience-item__top-icon img {
  display: block;
  width: 110px;
  height: 92px;
}
.audience-item__bottom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 68px;
  background-color: #544AF1;
  border-radius: 12px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 4;
}
.audience-item__bottom-icon img {
  display: block;
  width: 44px;
  height: 44px;
}
.audience-item__title {
  font-size: 30px;
  line-height: 100%;
  font-weight: 600;
  margin-bottom: 20px;
}
@media (max-width: 1365px) {
  .audience-item__title {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .audience-item__title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.audience-item__subtitle {
  font-size: 20px;
  line-height: 130%;
}
@media (max-width: 1365px) {
  .audience-item__subtitle {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .audience-item__subtitle {
    font-size: 16px;
  }
}

.slogan-section {
  padding-top: 160px;
  padding-bottom: 140px;
  background-color: #F49F0F;
  border-radius: 10px;
  background-image: url("./img/slogan-bg-pattern.png");
  background-repeat: repeat;
  background-size: 268px;
  background-position: top -30px left -30px;
}
@media (max-width: 1365px) {
  .slogan-section {
    padding-top: 130px;
    padding-bottom: 130px;
    background-size: 230px;
  }
}
@media (max-width: 1023px) {
  .slogan-section {
    padding-top: 110px;
    padding-bottom: 110px;
    background-size: 184px;
  }
}
@media (max-width: 767px) {
  .slogan-section {
    padding-top: 100px;
    padding-bottom: 100px;
    background-size: 160px;
  }
}
@media (max-width: 575px) {
  .slogan-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.slogan-section__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}
@media (max-width: 1365px) {
  .slogan-section__wrap {
    padding: 0;
  }
}
.slogan-section span {
  display: block;
  font-size: 60px;
  line-height: 100%;
  font-weight: 600;
  color: #FFFFFF;
  padding: 15px 30px;
  margin-bottom: 15px;
  background-color: #544AF1;
}
@media (max-width: 1365px) {
  .slogan-section span {
    font-size: 44px;
  }
}
@media (max-width: 1023px) {
  .slogan-section span {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .slogan-section span {
    font-size: 26px;
  }
}
@media (max-width: 575px) {
  .slogan-section span {
    width: 100%;
  }
}
.slogan-section span:last-child {
  margin-bottom: 0;
}

.manifesto {
  padding-top: 120px;
  padding-bottom: 140px;
}
@media (max-width: 1365px) {
  .manifesto {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .manifesto {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.manifesto__wrap {
  display: flex;
  align-items: flex-start;
  padding-left: 30px;
}
@media (max-width: 1365px) {
  .manifesto__wrap {
    padding-left: 0;
  }
}
@media (max-width: 575px) {
  .manifesto__wrap {
    display: block;
  }
}
.manifesto__img {
  flex: 1;
  display: block;
  width: 470px;
  margin-right: 100px;
}
@media (max-width: 1365px) {
  .manifesto__img {
    width: 400px;
    margin-right: 80px;
  }
}
@media (max-width: 1023px) {
  .manifesto__img {
    width: 336px;
    margin-right: 24px;
  }
}
@media (max-width: 767px) {
  .manifesto__img {
    width: 214px;
    margin-right: 14px;
  }
}
@media (max-width: 575px) {
  .manifesto__img {
    margin: 0 auto 40px;
  }
}
@media (max-width: 575px) {
  .manifesto__content {
    text-align: center;
  }
}
.manifesto__title {
  color: #2C255B;
}
.manifesto__description {
  font-size: 20px;
  line-height: 130%;
  margin-top: 40px;
}
@media (max-width: 1365px) {
  .manifesto__description {
    font-size: 18px;
    margin-top: 25px;
  }
}
@media (max-width: 767px) {
  .manifesto__description {
    font-size: 16px;
    margin-top: 20px;
  }
}
.manifesto__btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 44px;
}
@media (max-width: 1365px) {
  .manifesto__btn-wrap {
    margin-top: 35px;
  }
}
@media (max-width: 767px) {
  .manifesto__btn-wrap {
    margin-top: 30px;
  }
}
.manifesto__btn {
  margin-bottom: 10px;
}
.manifesto__btn:last-child {
  margin-bottom: 0;
}
.manifesto__btn--custom {
  text-align: left;
  background-color: #F49F0F;
  border: 2px solid #F49F0F;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 575px) {
  .manifesto__btn--custom {
    text-align: center;
  }
}
.manifesto__btn--custom:hover, .manifesto__btn--custom:active {
  opacity: 1;
  background-color: rgba(244, 159, 15, 0.6);
  border: 2px solid rgba(244, 159, 15, 0);
}

.contacts {
  padding-top: 120px;
  padding-bottom: 140px;
  background-color: #544AF1;
}
@media (max-width: 1365px) {
  .contacts {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .contacts {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.contacts__wrap {
  padding: 0 30px;
}
@media (max-width: 1365px) {
  .contacts__wrap {
    padding: 0;
  }
}
.contacts__title {
  color: #FFFFFF;
  margin-bottom: 40px;
}
@media (max-width: 1365px) {
  .contacts__title {
    margin-bottom: 30px;
  }
}
.contacts__description {
  font-size: 20px;
  line-height: 130%;
  color: #FFFFFF;
}
@media (max-width: 1365px) {
  .contacts__description {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .contacts__description {
    font-size: 16px;
  }
}
.contacts__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  font-size: 30px;
  line-height: 100%;
  color: #FFFFFF;
}
@media (max-width: 1365px) {
  .contacts__list {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .contacts__list {
    font-size: 18px;
  }
}

.subscription {
  width: 584px;
}
@media (max-width: 1365px) {
  .subscription {
    width: 524px;
  }
}
@media (max-width: 1023px) {
  .subscription {
    order: 1;
    width: 100%;
  }
}
.subscription__title {
  margin-bottom: 40px;
}
@media (max-width: 1365px) {
  .subscription__title {
    margin-bottom: 30px;
  }
}
@media (max-width: 1023px) {
  .subscription__title br {
    display: none;
  }
}
.subscription__form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
}
@media (max-width: 1365px) {
  .subscription__form {
    height: 74px;
  }
}
@media (max-width: 767px) {
  .subscription__form {
    height: 60px;
  }
}
.subscription__input {
  flex: 1;
  height: 100%;
  font-family: MacPaw Fixel;
  font-size: 20px;
  line-height: 130%;
  font-weight: 600;
  color: #FFFFFF;
  font-feature-settings: "salt" on;
  padding: 0 66px 0 36px;
  margin-right: -30px;
  background-color: transparent;
  border: 2px solid #FFFFFF;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
@media (max-width: 1365px) {
  .subscription__input {
    font-size: 18px;
    padding: 0 54px 0 30px;
    margin-right: -24px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
}
@media (max-width: 767px) {
  .subscription__input {
    font-size: 16px;
    padding: 0 44px 0 20px;
  }
}
@media (max-width: 575px) {
  .subscription__input {
    width: 200px;
  }
}
.subscription__input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.subscription__input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.subscription__input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.subscription__input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.subscription__input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.subscription__input:placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.subscription__input.invalid {
  border: 2px solid #FF8181;
  color: #FF8181;
}
.subscription__btn {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  color: #544AF1;
  padding: 0 36px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 30px;
  transition: background-color 0.3s ease-in-out;
}
@media (max-width: 1365px) {
  .subscription__btn {
    font-size: 18px;
    padding: 0 30px;
    border-radius: 24px;
  }
}
@media (max-width: 767px) {
  .subscription__btn {
    font-size: 16px;
    padding: 0 20px;
  }
}
.subscription__btn:hover, .subscription__btn:active {
  background-color: #F49F0F;
}

.contacts-email {
  width: calc(100% - 584px);
  padding-left: 114px;
}
@media (max-width: 1365px) {
  .contacts-email {
    width: calc(100% - 524px);
    padding-left: 36px;
  }
}
@media (max-width: 1023px) {
  .contacts-email {
    order: 3;
    width: auto;
    padding-left: 0;
    margin-top: 60px;
  }
}
@media (max-width: 575px) {
  .contacts-email {
    width: 100%;
  }
}
.contacts-email a {
  font-weight: 600;
  color: #FFFFFF;
  text-decoration: underline;
  text-underline-position: under;
  transition: opacity 0.3s ease-in-out;
}
.contacts-email a:hover, .contacts-email a:active {
  opacity: 0.4;
}

.contacts-facebook {
  width: 100%;
  margin-top: 80px;
}
@media (max-width: 1365px) {
  .contacts-facebook {
    margin-top: 60px;
  }
}
@media (max-width: 1023px) {
  .contacts-facebook {
    order: 2;
    width: auto;
    margin-right: 70px;
  }
}
@media (max-width: 767px) {
  .contacts-facebook {
    margin-right: auto;
    padding-right: 20px;
  }
}
@media (max-width: 575px) {
  .contacts-facebook {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
}
.contacts-facebook a {
  font-weight: 600;
  color: #FFFFFF;
  text-decoration: underline;
  text-underline-position: under;
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 1023px) {
  .contacts-facebook a {
    display: block;
  }
}
@media (max-width: 575px) {
  .contacts-facebook a {
    display: inline-block;
  }
}
.contacts-facebook a:hover, .contacts-facebook a:active {
  opacity: 0.4;
}